<template>
    <div class="password-page">

        <div style="width: 100%;margin-top: 40px;text-align: left;padding: 10px 25px;font-size: 16px;box-sizing: border-box">
            <div>您好</div>
            <div>欢迎来到<span style="font-weight: bold">七里游戏</span></div>
        </div>

        <div class="main-border">
            <van-form @submit="onSubmit" ref="submitForm">

                <div style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;display: flex;align-items: center">
                    <!--<van-image class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>-->
                    <span>重置密码</span>
                </div>

                <van-field label-width="60px" label="帐号"  placeholder="请输入七里帐号" class="border-input" v-model="account" clearable/>

                <van-field label-width="60px" type="password" label="密码" style="margin-top: 10px;" placeholder="请输入新的登录密码" class="border-input" v-model="password" clearable/>

                <van-field label-width="60px" type="password" label="确认密码" style="margin-top: 10px;" placeholder="请输入新的登录密码" class="border-input" v-model="confirmPassword" clearable/>


                <van-field label-width="60px" label="验证码"  style="margin-top: 10px;"  v-model="verifyCode" type="number" clearable
                           placeholder="请输入验证码">
                    <template #button>
                        <van-count-down
                                v-show="showCountDown"
                                ref="countDown"
                                :time="60000"
                                :auto-start="false"
                                format="重新发送(ss秒)"
                                style="font-size: 13px;color: #888888"
                                @finish="finish"
                        />
                        <span  style="color: #5A6EFC;font-size: 12px;" v-show="!showCountDown" @click="sendSmsForReset">发送验证码</span>
                    </template>
                </van-field>

                <div class="box-button">
                    <van-button type="info" block native-type="submit" :loading="loading" style="height: 35px;"
                                loading-text="密码重置中"
                                color="#5A6EFC">
                        确认重置
                    </van-button>
                    <div style="font-size: 12px;margin-top: 15px;display: flex;justify-content: space-between;padding: 0 3px;">
                        <div class="text-button">如有疑问，请<span  @click="toCustomer" style="color: #3478F6">联系客服</span></div>
                        <div @click="back" style="color: #3478F6">返回登录页面</div>
                    </div>

                </div>

            </van-form>

        </div>
    </div>
</template>

<script>

    import {sendSms,modifyPasswordByPhone} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import accountIcon from "@/assets/account.png";
    import passwordIcon from "@/assets/password.png";
    import verifyCodeIcon from "@/assets/verifyCode.png";
    import backIcon from "@/assets/back.png";
    import { Toast } from 'vant'

    export default {
        name: 'resetPassword',
        data() {
            return {
                accountIcon,
                passwordIcon,
                verifyCodeIcon,
                backIcon,
                account: '',
                password: '',
                confirmPassword: '',
                verifyCode: '',
                accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
                passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+]{6,12}$/,
                codePattern: /^\d{6}$/,
                showCountDown: false,
                showSendText: true,
                disabledSend: false,
                loading: false,
            };
        },
        methods: {
            back() {
                this.$router.back();
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                if (!this.codePattern.test(this.verifyCode)) {
                    Toast("验证码为6位数字");
                    return ;
                }
                this.loading = true;
                let params = {
                    device: 'H5',
                    newPassword: crypto.encrypt(this.password),
                    userName: this.account,
                    checkCode: this.verifyCode
                };
                modifyPasswordByPhone(params).then((res) => {
                    this.loading = false;
                    if (res.data.success === true) {
                        Toast('密码重置成功');
                        this.back();
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            sendSmsForReset() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }

                this.disabledSend = true;
                this.showSendText = false;
                this.showCountDown = true;
                this.$refs.countDown.start();
                let params = {
                    device: 'H5',
                    type: '5',
                    phone: this.account
                };
                sendSms(params).then((res) => {
                    if (res.data.success === true) {
                        Toast('验证码发送成功');
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            checkForm() {
                if (!this.account) {
                    return "请输入七里帐号";
                }
                if (!this.accountPattern.test(this.account)) {
                    return "七里帐号为6-12位数字字母";
                }
                if (!this.password) {
                    return "请输入密码";
                }
                if (!this.passwordPattern.test(this.password)) {
                    return "密码为6-12位数字字母特殊符号";
                }
                if (!this.confirmPassword) {
                    return "请再次输入密码";
                }
                if (this.password !== this.confirmPassword) {
                    return "两次密码输入不一致，请重新输入";
                }
                return "ok";
            },
            toCustomer() {
                this.$router.push({path: '/customerService'});
            },
            finish() {
                this.disabledSend = false;
                this.showSendText = true;
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            checkPassword(val) {
                return this.password === this.confirmPassword;
            },
        }
    }
</script>

<style scoped lang="scss">
    .password-page {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        .main-border {
            text-align: center;
            background-color: white;
            border-radius: .3rem;
            width: 85%;
            border: 1px solid #eceaea;
            padding: 20px 20px;
            box-sizing: border-box;
            margin-top: 15px;

            .down-input{
                margin-top: .8rem;
            }
            .van-cell{
                padding: 8px 16px;
            }

            .box-button {
                margin-top: 15px;
                .bottom-button{
                    font-size: .8rem;
                    margin-top: .8rem;
                }
            }
        }

        .van-field{
            outline: 1px #dbdbdb solid;
            border-radius: 3px;
        }
        .van-field:focus-within{
            outline: 2px #3478F6 solid;
        }
        /deep/.van-field__control {
            //color: #3478F6;
        }

    }
</style>
