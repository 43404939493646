<template>
  <div class="main-container">
    <div v-if="showBack" style="text-align: left;width: 100%;position: absolute;top: 40px;padding-left: 20px;">
      <van-icon name="arrow-left" size="25" @click="back"/>
    </div>

    <div class="main-border">
      <van-form @submit="onSubmit" ref="submitForm">

        <van-image :src="qiliLogo" width="5.5rem" height="5.5rem"/>

        <van-row class="line-row" style="display: flex;align-items: center;justify-content: center">
          <van-col span="16">
            <van-field
                    v-model="account"
                    label-width="2.5rem"
                    label="帐号"
                    placeholder="请输入七里帐号"
                    autocomplete="off"
                    name="telRule"
                    :rules="[{ pattern : accountPattern, required: true, message: '帐号为6-12位字母数字或下划线组成'}]"
                    class="file-van"
            />
          </van-col>
          <van-col span="8" class="right-line-button">
            <van-icon name="arrow-down" @click="selectOtherAccount"/>
          </van-col>
        </van-row>

        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
                  show-toolbar
                  :columns="accountColumns"
                  @cancel="showPicker = false"
                  @confirm="pickAccount"
                  @change="onChange"
          />
        </van-popup>

        <div class="width-line"></div>
        <van-row class="line-row">
          <van-col span="16">
            <van-field
                    style="margin-top: .8rem"
                    label-width="2.5rem"
                    label="密码"
                    v-model="password"
                    placeholder="请输入登录密码"
                    name="passwordRule"
                    :rules="[{ pattern : passwordPattern, required: true, message: '密码长度为6-12位' }]"
                    type="password"
                    aria-autocomplete="none"
                    autocomplete="off"
                    class="file-van"
            />
          </van-col>
          <van-col span="8" class="right-line-button">
          </van-col>
        </van-row>
        <div class="width-line"></div>

        <van-row class="forget-row">
          <van-col span="8" offset="2">
            <van-checkbox icon-size=".8rem" v-model="rememberPassword" shape="square">记住密码</van-checkbox>
          </van-col>
          <van-col span="8" offset="4">
            <span @click="toForgetPage">忘记密码</span>
          </van-col>
        </van-row>
        <div class="box-button">
          <van-button round type="info" block native-type="submit" :loading="loading"
                      loading-text="登录中"
                      color="linear-gradient(to right, rgb(153, 200, 0), rgb(153, 200, 0))">
            登  录
          </van-button>
          <p @click="toRegisterPage" class="text-button">还没有帐号？去注册</p>
        </div>
      </van-form>

    </div>
  </div>
</template>

<script>

import {login} from '@/api/request'
import crypto from '@/utils/crypto.js';
import qiliLogo from "@/assets/qiliLogo.png";
import accountIcon from "@/assets/account.png";
import passwordIcon from "@/assets/password.png";

import { Toast } from 'vant'

export default {
  name: 'login',
  data() {
    return {
      qiliLogo,
      accountIcon,
      passwordIcon,
      account: '',
      password: '',
      rememberPassword: false,
      accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
      passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,12}$/,
      loading: false,
      channelId: '',
      gameId: '',
      accountColumns: [],
      showPicker: false,
      userList: [],
      currentIndex : 0,
      showBack: false,
    };
  },
  mounted() {
    this.channelId = localStorage.getItem('channelId');
    this.gameId = localStorage.getItem('gameId');
    let token = localStorage.getItem('token');
    if(token){
      this.$router.replace({ path: `/play/${this.channelId}/${this.gameId}`});
    }
    let isRemember = localStorage.getItem('rememberPassword');
    if (isRemember === "true") {
      this.rememberPassword = true;
      this.account = localStorage.getItem('username');
      this.password = crypto.decrypt(localStorage.getItem('password'));
    }
    let from = localStorage.getItem('from');
    if (from === 'qiliApp') {
      this.showBack = true;
    }

    this.userList = JSON.parse(localStorage.getItem('userList')) || [];
    if (this.userList.length > 0) {
      this.userList.forEach((v,i) => {
        this.accountColumns.push(v.u);
      })
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    onSubmit() {
      this.loading = true;
      let params = {
        channelId: this.channelId ,
        device: 'H5' ,
        gameId: this.gameId ,
        userName: this.account,
        password: crypto.encrypt(this.password)
      };
      login(params).then(res => {
        this.loading = false;
        if(res.data.success) {
          localStorage.setItem('token', res.data.data.token);
          localStorage.setItem('user', JSON.stringify(res.data.data));
          localStorage.setItem('loginUrl', res.data.data.loginUrl);

          this.handlerRememberPassword();
          Toast.success('登录成功');
          this.$router.replace({ path: `/play/${this.channelId}/${this.gameId}`});
        }
        else {
          Toast.fail(res.data.error.message);
        }
      });
    },
    selectOtherAccount() {
      this.showPicker = true;
    },
    pickAccount() {
      this.showPicker = false;
      let user = this.userList[this.currentIndex];
      this.account = user.u;
      this.password = crypto.decrypt(user.p);
    },
    onChange(picker, value, index) {
      this.currentIndex = index;
    },
    toForgetPage() {
      this.$router.push({path: '/reset'});
    },
    toRegisterPage() {
      this.$router.push({path: '/register'});
    },
    handlerRememberPassword() {
      localStorage.setItem('rememberPassword', this.rememberPassword);

      this.userList = JSON.parse(localStorage.getItem('userList')) || [];
      let has = false;
      let index = -1;
      this.userList.forEach((v, i) => {
        if (v.u === this.account) {
          has = true;
          index = i;
        }
      })

      let password = crypto.encrypt(this.password);
      localStorage.setItem('username', this.account);
      localStorage.setItem('password', password);

      if (this.rememberPassword) {
        if (!has) {
          let item = {
            u: this.account,
            p: password,
          }
          this.userList.push(item);
          localStorage.setItem('userList', JSON.stringify(this.userList));
        }
      }
      else {
        if (has) {
          this.userList.splice(index, 1);
          localStorage.setItem('userList', JSON.stringify(this.userList));
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-container {
  background-color: #f8f7f7;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .main-border {
    max-width: 500px;
    text-align: center;
    background-color: white;
    border-radius: .3rem;
    width: 84%;

    border: 1px solid #eceaea;
    padding: .7rem .5rem;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .select-other{
      margin-top: 10px;
      display: block;
      font-size: 12px;
      color: #b3b3b6;
    }

    .width-line{
      height: 1px;
      margin: 0 1.04rem;
      border-bottom: 1px solid #b3b3b6;
    }

    .forget-row{
      margin-top: 1.5rem;
      font-size: .8rem;
    }
    .box-button {
      margin: 1.7rem 1rem 0;

      .text-button{
        font-size: .8rem;
        margin-top: .8rem;
        color: rgb(153, 200, 0);
      }
    }
    .van-cell::after {
      border-bottom: 1px solid #4d4e50;
    }
  }
}
</style>
